<template>
  <div v-if="$store.state.metadata" :data-size="size" :data-center="center">
    <h1 class="mb-0">
      <div class="org-name d-flex align-items-center">
        <ASPSLogo v-if="$store.state.metadata.shortOrgName === 'ASPS'" />
        <small>
          <span>
            {{ $store.state.metadata.orgName }}
          </span>
        </small>
      </div>
      {{ $store.state.metadata.serviceName }}
    </h1>
  </div>
</template>

<script>
import ASPSLogo from "./ASPSLogo";

export default {
  name: "AppTitle",
  components: { ASPSLogo },
  props: {
    size: { type: String, default: "sm" },
    center: { type: Boolean }
  }
};
</script>

<style scoped lang="scss">
h1 {
  font-weight: 700;
  font-size: 1.2em;
  text-align: left;

  @media (min-width: 576px) {
    font-size: 1.4em;
  }

  small {
    display: block;
    font-size: 0.42em;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  svg {
    width: 16px;
  }
}

div[data-size="lg"],
div[data-size="xl"] {
  text-align: center;

  h1 {
    font-size: 1.4em;
    font-weight: 900;

    small {
      font-size: 8px;

      @media (min-width: 350px) {
        font-size: 0.3em;
      }

      @media (min-width: 576px) {
        font-size: 0.35em;
      }
    }

    @media (min-width: 350px) {
      font-size: 2em;
    }
    @media (min-width: 576px) {
      font-size: 2.3em;
    }

    svg {
      width: 15px;

      @media (min-width: 576px) {
        width: 20px;
      }
    }
  }
}

div[data-size="xl"] {
  h1 {
    font-size: 2.7em;

    @media (min-width: 576px) {
      font-size: 3.65em;
    }

    svg {
      width: 25px;

      @media (min-width: 576px) {
        width: 30px;
      }
    }
  }
}

div[data-center] {
  text-align: center;

  h1 {
    text-align: center;
  }

  .org-name {
    justify-content: center !important;
  }
}
</style>