import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex)

import Enumerable from "linq";
import lodash from "lodash";
import browserStore from "store2";

import { getJson, postJson, postData } from "@/utils/dataTransfer.js";

const endpointBaseUrl = process.env.VUE_APP_API_ENDPOINT;
const vendorEndpointUrl = endpointBaseUrl.includes("aspx") ? endpointBaseUrl : `${endpointBaseUrl}vendors.aspx?m=`;

//Get initial values from session storage
const authToken = browserStore("authToken") ?? browserStore.session("authToken");
const currentUser = browserStore.session("currentUser");

export default new Vuex.Store({
  state: {
    authToken,
    currentUser,
    startingRoute: undefined,
    collectionVendors: {},
    searchPlaceholders: [],
    categories: [],
    collections: [],
    adminVendors: undefined,
    metadata: undefined,
    favorites: undefined
  },
  mutations: {
    setStartingRoute(state, route) {
      state.startingRoute = route;
    },
    setLogIn(state, data) {
      state.authToken = data?.token;
      state.currentUser = data?.user;

      const persist = data?.persist;

      if (!data?.token) {
        browserStore.session.remove("authToken");
        browserStore.remove("authToken");
      } else if (persist) {
          browserStore("authToken", data.token);
      } else {
        browserStore.session("authToken", data.token);
      }

      if (!data?.user) {
        browserStore.session.remove("currentUser");
        browserStore.remove("currentUser");
      } else {
        browserStore.session("currentUser", data.user);
      }
    },
    setVendorsForCollection(state, data) {
      state.collectionVendors[data.collectionKey] = data.vendors;
    },
    setSearchPlaceholders(state, placeholders) {
      state.searchPlaceholders = placeholders;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setCollections(state, collections) {
      state.collections = collections;
    },
    setMetadata(state, metadata) {
      state.metadata = metadata;
    },
    setAdminVendors(state, vendors) {
      state.adminVendors = vendors;
    },
    setFavorites(state, vendors) {
      state.favorites = vendors;
    }
  },
  actions: {
    //Logs a user in and sets the appropriate auth token in session storage
    logIn({ state, commit, dispatch }, data) {
      const url = `${vendorEndpointUrl}login`;

      return new Promise((resolve, reject) => {
        postJson(url, {...data, token: state.authToken}).then(
          d => {
            if (!d || d.Message) {
              if (d.Code === "BadToken") {
                commit("setLogIn", undefined);
                resolve();
              }
              else reject({ message: d.Message ?? "Unknown Error"});
              return;
            }
            commit("setLogIn", {...d, persist: data.persist});
            resolve(d);
            dispatch("fetchVendorsForAdmin");
            dispatch("log", "login");
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Logging In"})
        );
      });
    },

    //Gets a list of available categories
    fetchCategories({ commit, state }) {
      return new Promise((resolve, reject) => {

        //Use local cache if available
        if (state.categories?.length > 0) {
          resolve(state.categories);
          return;
        }

        const url = `${vendorEndpointUrl}categories`;

        getJson(url, state.authToken).then(
          d => {
            commit("setCategories", d);
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Loading Categories"})
        );

      });
    },

    //Gets search placeholder suggestions
    fetchSearchPlaceholders({ commit, state }) {
      return new Promise((resolve, reject) => {
        
        //Use local cache if available
        if (state.searchPlaceholders?.length > 0) {
          resolve(lodash.shuffle(state.searchPlaceholders));
          return;
        }

        const url = `${vendorEndpointUrl}searchplaceholders`;

        getJson(url, state.authToken).then(
          d => {
            commit("setSearchPlaceholders", d);
            resolve(lodash.shuffle(d));
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Loading Search Placeholders"})
        );

      });
    },

    //Gets a specific category by category key
    fetchCategoryByKey({ dispatch }, categoryKey) {
      return new Promise((resolve, reject) => {
        dispatch("fetchCategories").then((d) => {
          const c = Enumerable.from(d).firstOrDefault(c => c.key.toLowerCase() === categoryKey.toLowerCase());
          if (c) {
            resolve(c);
          } else {
            reject({code: "NotFound", message: "Category Not Found" });
          } 
        }, e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Loading Category"}));
      });
    },

    //Gets all vendors for a particlar category by category key
    fetchVendorsForCategoryKey({ state }, categoryKey) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}vendorsforcategorykey&name=${categoryKey}`;

        getJson(url, state.authToken).then(
          d => {
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Loading Vendors for Category"})
        );

      });
    },

    //Gets a specific vendor's data by vendor key
    fetchVendorByKey({ state }, vendorKey) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}vendorforkey&name=${vendorKey}`;

        getJson(url, state.authToken).then(
          d => {
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Loading Vendor"})
        );
      });
    },

    //Gets matching vendors and/or categories for a provided search query
    fetchSearch({ state }, data) {
      return new Promise((resolve, reject) => {

        if (!data?.query) {
          resolve({});
          return;
        }

        const url = `${vendorEndpointUrl}search&query=${data.query}&instant=${!!data.instant}`;

        getJson(url, state.authToken).then(
          d => {
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Getting Search Results"})
        );

      });
    },

    //Gets a list of available collections
    fetchCollections({ commit, state }) {
      return new Promise((resolve, reject) => {

        //Use local cache if available
        if (state.collections?.length > 0) {
          resolve(state.collections);
          return;
        }

        const url = `${vendorEndpointUrl}collections`;

        getJson(url).then(
          d => {
            commit("setCollections", d);
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Loading Collections"})
        );

      });
    },

    //Gets first question for collection and vendors if already completed
    fetchCollectionStart({ state }, collectionKey) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}collectionstartforkey&name=${collectionKey}`;

        getJson(url).then(
          d => {
            const collection = d.collection;
            const question = d.question;
            const vendors = state.collectionVendors[collection.id];

            resolve({
              collection,
              vendors,
              question
            });
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Loading Collection"})
        );

      });
    },

    //Submits question and gets next question or a list of vendors
    submitCollectionAnswers({ state, commit, dispatch }, data) { 
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}submitcollectionanswers`;

        postJson(url, data, state.authToken).then(
          d => {
            commit("setVendorsForCollection", d);
            resolve(d);
            if (d.vendors) dispatch("log", { action: "collection-answers", collectionKey: data.collectionKey, answers: data.answers});
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Submitting Answer"})
        );
      });
    },



    //Gets all vendors that user can admin
    fetchVendorsForAdmin({ state, commit }) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}vendorstoadmin`;

        getJson(url, state.authToken).then(
          d => {
            commit("setAdminVendors", d);
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Loading Vendors for Admin"})
        );

      });
    },


    fetchVendorAnalyticsEarliestDate({ state }, vendorKey) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}vendoranalyticsfirstdate&name=${vendorKey}`;

        getJson(url, state.authToken).then(
          d => {
            resolve(d);
          },
          e => {
            reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Getting Vendor Analytics Earliest Date"})
          }
        );

      });
    },

    fetchVendorAnalytics({ state }, data) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}vendoranalytics&name=${data.vendorKey}&days=${data.daysBack}`;

        getJson(url, state.authToken).then(
          d => {
            resolve(d);
          },
          e => {
            reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Getting Vendor Analytics"})
          }
        );

      });
    },

    
    //Get general application metadata
    fetchMetadata({ commit }) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}metadata`;

        getJson(url).then(
          d => {
            commit("setMetadata", d);
            resolve(d);
          },
          e => {
            commit("setMetadata", { });
            reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Getting App Metadata"})
          }
        );

      });
    },


    //Push vendor changes to server
    pushVendorChanges({ state }, data) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}edit`;

        postJson(url, data, state.authToken).then(
          d => {
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Updating Vendor"})
        );
      });
    },


    uploadImage({ state }, formData) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}uploadImage`;

        postData(url, formData, state.authToken).then(
          d => {
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Uploading Image"})
        );
      });
    },

    //Gets a specific vendor's users by vendor key
    fetchVendorUsersByKey({ state }, vendorKey) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}vendorusers&name=${vendorKey}`;

        getJson(url, state.authToken).then(
          d => {
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Loading Users"})
        );
      });
    },

    //Push vendor user edits to server
    editVendorUser({ state }, data) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}edituser`;

        postJson(url, data, state.authToken).then(
          d => {

            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Editing User"})
        );
      });
    },

    //Push new vendor user to server
    addVendorUser({ state }, data) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}adduser`;

        postJson(url, data, state.authToken).then(
          d => {
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Adding User"})
        );
      });
    },

    //Delete vendor user from system
    deleteVendorUser({ state }, data) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}deleteuser`;

        postJson(url, data, state.authToken).then(
          d => {
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Deleting User"})
        );
      });
    },

    //Reset user password from system
    resetUserPassword(_, data) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}resetpassword`;

        postJson(url, data).then(
          d => {
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Resetting Password"})
        );
      });
    },

    //Push new vendor to server
    createNewVendor({ state }, data) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}createvendor`;

        postJson(url, data, state.authToken).then(
          d => {
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Creating Vendor"})
        );
      });
    },

    //Push category changes to server
    pushCategoryChanges({ commit, state }, data) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}editcategory`;

        postJson(url, data, state.authToken).then(
          d => {
            commit("setCategories", d);
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Updating Category"})
        );
      });
    },

    log({ state }, data) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}log`;

        if (lodash.isString(data)) data = { action: data };

        postJson(url, data, state.authToken).then(
          d => {
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Logging"})
        );
      });
    },


    fetchFavorites({ state, commit }) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}favorites`;

        getJson(url, state.authToken).then(
          d => {
            commit("setFavorites", d);
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Loading Favorites"})
        );
      });
    },

    addFavorite({ state, commit }, vendor) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}addfavorite`;

        postJson(url, { vendorKey: vendor.key }, state.authToken).then(
          d => {
            commit("setFavorites", [...state.favorites, vendor]);
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Logging"})
        );
      });
    },

    removeFavorite({ state, commit }, vendor) {
      return new Promise((resolve, reject) => {

        const url = `${vendorEndpointUrl}removefavorite`;

        postJson(url, { vendorKey: vendor.key }, state.authToken).then(
          d => {
            commit("setFavorites", state.favorites.filter(f => f.key !== vendor.key));
            resolve(d);
          },
          e => reject({ code: e?.code ?? "Unknown", message: e?.message ?? "Error Logging"})
        );
      });
    }
  },
  getters: {
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
    appName(state) {
      return `${state.metadata.shortOrgName ?? ""} ${state.metadata.serviceName ?? ""}`;
    },
    loggedIn(state) {
      return !!(state.authToken && state.currentUser);
    }
  },
  modules: {
  }
})
