<template>
  <b-form-group>
    <template #label>
      <strong v-if="required">
        <span>{{ label }}</span>
      </strong>
      <span v-else>{{ label }}</span>
      <transition name="fade">
        <b-badge
          v-if="editing && remainingShouldDisplay"
          :variant="badgeVariant"
          class="ml-1"
        >
          {{ charactersRemaining }}
          {{ compact ? "chars left" : "characters remaining" }}
        </b-badge>
      </transition>
    </template>
    <template v-if="editing">
      <b-input
        v-if="!rows"
        v-model="localValue"
        type="text"
        :maxlength="maxLength"
        @focus="hasFocus = true"
        @blur="hasFocus = false"
      />
      <b-textarea
        v-else
        :rows="rows"
        :maxlength="maxLength"
        v-model="localValue"
        @focus="hasFocus = true"
        @blur="hasFocus = false"
        :max-rows="maxRows"
      ></b-textarea>
    </template>
    <span v-if="!editing" @click="$emit('edit')">
      {{ localValue }}
    </span>
  </b-form-group>
</template>

<script>
export default {
  name: "TextFieldWithLimit",
  props: {
    label: { type: String },
    maxLength: { type: Number, required: true },
    editing: { type: Boolean },
    value: { type: String },
    showRemainingAt: { type: Number, default: 20 },
    rows: { type: Number },
    maxRows: { type: Number },
    alwaysShowRemaining: { type: Boolean },
    compact: { type: Boolean },
    required: { type: Boolean }
  },
  data() {
    return {
      hasFocus: false
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    charactersRemaining() {
      return this.maxLength - (this.value ?? "").length;
    },
    remainingShouldDisplay() {
      return (
        this.hasFocus &&
        (this.alwaysShowRemaining ||
          this.charactersRemaining <= this.showRemainingAt)
      );
    },
    badgeVariant() {
      if (this.charactersRemaining === 0) {
        return "danger";
      }
      if (this.charactersRemaining < 11) {
        return "warning";
      }
      return "primary";
    }
  }
};
</script>
