const getJson = (url, token) => {
    const requestData = {};
    if (token) {
        requestData.headers = {
        Authorization: `Bearer ${token}`
        };
    }
    return new Promise((resolve, reject) => {
        let hasError = false;
        fetch(url, requestData)
        .then(
            r => {
                hasError = r.status != 200;
                return r.json()
            },
            () => reject("Error parsing JSON")
        )
        .then(
            d => {
                if (hasError) reject(d);
                else resolve(d);
            },
            () => reject("Error processing data")
        );
    });
};

const postData = (url, data, token) => {
    return new Promise((resolve, reject) => {
        let hasError = false;
        const requestData = {
            method: "POST",
            body: data
        };
        if (token) {
            requestData.headers = {
                Authorization: `Bearer ${token}`
            };
        }
        fetch(url, requestData)
        .then(
            r => {
                hasError = r.status != 200;
                return r.json()
            },
            () => reject("Error parsing JSON")
        )
        .then(
            d => {
                if (hasError) reject(d);
                else resolve(d);
            },
            () => reject("Error processing data")
        );
    });
};

const postJson = (url, requestBodyData, token) => {
    return postData(url, JSON.stringify(requestBodyData), token);
};

export { getJson, postJson, postData };
