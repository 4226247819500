<template>
  <div v-if="$store.state.metadata && $store.state.metadata.orgName" id="app">
    <link
      v-if="customStylesheet"
      rel="stylesheet"
      :href="customStylesheet"
      type="text/css"
    />
    <router-view />

    <p class="text-center text-muted mt-5 mb-3 copyright">
      <small>
        <template v-if="$store.state.metadata">
          Copyright &copy; 2021 {{ $store.state.metadata.orgName }}
        </template>
        <br />
        <small class="text-muted">
          version {{ $store.getters.appVersion }}
          <strong v-if="envName"> [{{ envName }}] </strong>
        </small>
      </small>
    </p>
  </div>
  <vc-loading v-else-if="$store.state.metadata" class="p-4" has-error>
    Error Loading Application
  </vc-loading>
  <vc-loading v-else class="p-4">Loading...</vc-loading>
</template>

<script>
export default {
  name: "App",
  computed: {
    envName() {
      const envName = process.env.VUE_APP_ENV_NAME;
      return envName === "production" ? "" : envName;
    },
    customStylesheet() {
      if (!this.$store.state.metadata?.customStylesheetUrl) return "";
      return this.$store.state.metadata.customStylesheetUrl;
    }
  }
};
</script>

<style lang="scss">
$animationDuration: 0.3s;
@import "vue2-animate/src/sass/vue2-animate.scss";

$primary: rgb(63, 88, 113);
$secondary: rgb(250, 167, 1);
@import "bootstrap/scss/bootstrap.scss";

@import "bootstrap-vue/src/index.scss";

@import "vue-advanced-cropper/dist/style";

$base-color: $primary;
@import "vue-advanced-cropper/dist/theme.bubble.scss";

.vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
  background: white;
}
</style>

<style scoped lang="scss">
.copyright {
  line-height: 1;

  small small {
    opacity: 0.6;
  }
}
</style>